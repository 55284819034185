import React, {useEffect, useState} from 'react'
import {Grid} from '@mui/material'
import users from 'images/users.svg'
import Typography from '@mui/material/Typography'
import playFill from 'images/play_fill.svg'
import petFinder from 'images/petFinder.svg'
import lostPets from 'images/lostPets.svg'
import flag from 'images/flagged.svg'
import {socketClient} from 'client/socket.client'

export const Analytics = props => {
  const [analytics, setAnalytics] = useState({})
  useEffect(() => {
    socketClient.io.emit('report', 'analytics', {}, {}, (e, data) => {
      if (e) {
        console.error(e)
        return
      }
      setAnalytics(data)
    })
  }, [])

  const Card = props => {
    return (
      <Grid
        item
        boxShadow="custom"
        bgcolor="white"
        xs={18}
        sm={4}
        md={3}
        lg={3}
        sx={{display: 'block', borderRadius: '10px', p: 3, m: 1, border: '1px solid #DDDDDD'}}
      >
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <img src={props?.img} alt="img" />
        </Grid>

        <Typography fontSize="12px" my="4px">
          {props?.title}
        </Typography>
        <Typography variant="h4" fontSize="26px" sx={{fontWeight: 'bold', color: '#09244B'}}>
          {props?.count}
        </Typography>
        <Typography
          sx={{
            color: '#2AC670',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            gap: '2px'
          }}
        >
          <img src={playFill} alt="play" />
          %10
        </Typography>
      </Grid>
    )
  }

  return (
    <Grid container spacing="10px" alignItems="center" columns={18}>
      <Card img={users} title="App Users" count={analytics?.users} />
      <Card img={petFinder} title="Pet Finders" count={analytics?.petFinders} />
      <Card img={lostPets} title="Lost Pets" count={analytics?.lostPets} />
      <Card img={flag} title="Flagged Users" count={analytics?.flaggedUsers} />
      <Card img={lostPets} title="Adoptions" count={analytics?.adoptions} />
    </Grid>
  )
}
