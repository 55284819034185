import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Grid, Button, Switch, Box, IconButton, Typography, Divider } from "@mui/material";
import { ImageInput } from "components/media-input/ImageInput";
import { connectService } from "components/service/connectService";
import { SnackbarContext } from "components/snackbar/SnackbarContext";
import { useFormik } from "formik";
import {useGeoCurrentLocation} from 'hooks/useGeoCurrentLocation'
import { useEffect, useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete'
import './../../../components/css/image-uploader.css'
import { find, lowerCase, map } from "lodash";
import { ImagePreview } from "components/ImagePreview";
import { useMediaUpload } from "hooks/useMediaUpload";
import { socketClient } from "client/socket.client";
import { getFullFormatedAddress } from "utils/utilities";
import ReadOnlyProperty from "./ReadOnlyProperty";

const CreateUpdateForm = connectService((props) => {
  const {create, data, id, patch} = props
  const {loading, uploadMedia} = useMediaUpload()
  const {getCurrentLocation} = useGeoCurrentLocation()
  const {setSeverity, showToast} = useContext(SnackbarContext)
  const navigate = useNavigate()
  const [selectedMedia, setSelectedMedia] = useState([])
  const [currentLocation, setCurrentLocation] = useState([])
  const [uploadedMedia, setUploadedMedia] = useState([])
  const [poster, setPoster] = useState(null)
  const [deletedMedia, setDeletedMedia] = useState([])
  const [location, setLocation] = useState(null)
  const [reportedLocation, setReportedLocation] = useState(null)

  useEffect(() => {
    getCurrentLocation()
      .then(location => {
        console.log(location)
        setCurrentLocation(location)
      })
      .catch(e => {
        console.log(e)
        setSeverity('error')
        showToast(e)
      })
  }, [])

  useEffect(() => {
    if (data?.location?.length === 2) {
      getFullFormatedAddress(data?.location[1], data?.location[0])
        .then((address) => setLocation(address))
        .catch(console.log)
    }

    if (data?.reportedLocation?.length === 2) {
      getFullFormatedAddress(data?.reportedLocation[1], data?.reportedLocation[0])
        .then((address) => setReportedLocation(address))
        .catch(console.log)
    }
  }, [data])   
    
  const handleSubmit = async(values) => {
    const _media = uploadedMedia || []
    let _poster = data?.poster

    if (selectedMedia?.length) {
      try {
        for (let i = 0; i < selectedMedia.length; i++) {
          const media = await uploadMedia(selectedMedia[i].file)
          _media.push(media[0])
        }
      } catch (error) {
        console.log('upload-failed')
        setSeverity('error')
        showToast('Failed to upload pet photos!')
        return
      }
    }

    if (deletedMedia?.length) {
      try {
        for (let i = 0; i < deletedMedia.length; i++) {
          await socketClient.service('media').remove(deletedMedia[i])
        }
      } catch (error) {
        console.log('failed-to-delete-media', error)
      }
    }

    if (poster?.file) {
      try {
          const media = await uploadMedia(poster.file)
          _poster = media[0]
        
      } catch (error) {
        console.log('upload-failed')
        setSeverity('error')
        showToast('Failed to upload poster!')
        return
      }

      if (data?.poster) {
        try {
          await socketClient.service('media').remove(data?.poster?._id)
        } catch (error) {
          console.log('failed-to-delete-poster', error)
        }
      }
    }

    try {
      const {injured,inControl, ...payload} = values

      payload['questionnaire'] = [
        {question: 'injured', answer:  injured ? 'yes' : ''},
        {question: 'inControl', answer: inControl ? 'yes' : ''}
      ]
      
      if (!payload?.poster) delete payload.poster
      
      payload.media = map(_media, m => m?._id)

      if (_poster?._id) {
        payload.poster = _poster?._id
      }
      
      if (id) {
        patch(id, payload)
      } else {
        if (currentLocation?.length) {
          payload['location'] = currentLocation
          payload['reportedLocation'] = currentLocation
        }
        create(payload)
      }
      
      navigate(-1)  
    } catch (error) {
      console.log(error)
    } 
  }
  
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  useEffect(() => {
    if (data?._id ) {
      const injured =  data?.questionnaire?.length ? find(data?.questionnaire, (i) => i.question === 'injured') : null
      const inControl =  data?.questionnaire?.length ? find(data?.questionnaire, (i) => i.question === 'inControl') : null

      if (data?.media?.length) setUploadedMedia(data?.media)

      if (data?.poster) setPoster(data?.poster)
      formik.setValues({
        type: data?.type || '',
        rescueType: data?.rescueType || [],
        address: data?.address || '',
        description: data?.description || '',
        hasCollarOrTag: data?.hasCollarOrTag,
        injured: injured?.answer && lowerCase(injured?.answer) === 'yes' ? true : false,
        inControl: inControl?.answer && lowerCase(inControl?.answer) === 'yes' ? true : false,
      })
    }
  }, [data])


  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="md:p-8 w-full flex flex-col items-start">

        <div className="w-full flex flex-col lg:flex-row gap-4">
          <div className="w-full md:w-1/2">
            <Grid container spacing={3} sx={{textAlign: 'left'}}>
             {data?.user?.firstName && data?.user?.lastName && (<Grid item xs={12}>
                <ReadOnlyProperty label={'Reported By'} value={`${data?.user?.firstName} ${data?.user?.lastName}`}/>
              </Grid>)}
              
              {location && <Grid item xs={12}>
                <ReadOnlyProperty label='Location' value={location}/>
              </Grid>}
              
              {reportedLocation && <Grid item xs={12}>
                <ReadOnlyProperty label='Reported Location' value={reportedLocation}/>
              </Grid>}
              
              {(data?.user || location || reportedLocation) && (<Grid xs={12}>
                <Divider sx={{m: 2}}/>
              </Grid>)}

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="type">Report Type</InputLabel>
                  <Select
                    labelId="type"
                    name="type"
                    value={formik.values.type || ''}
                    label="Type"
                    onChange={formik.handleChange}
                  >
                    <MenuItem value='sighting'>Sighting</MenuItem>
                    <MenuItem value='inControl'>In Control</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="rescueType">Rescue Type</InputLabel>
                  <Select
                    labelId="rescueType"
                    name="rescueType"
                    value={formik.values.rescueType}
                    label="Rescue Type"
                    onChange={formik.handleChange}
                    multiple
                  >
                    <MenuItem value='owner'>Owner</MenuItem>
                    <MenuItem value='temporaryFoster'>Temporary Foster</MenuItem>
                    <MenuItem value='adoptionPlacement'>Adoption Placement</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}  fullWidth>
                <TextField
                  name="address"
                  label="Address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  multiline
                  rows={3}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} fullWidth>
                <TextField
                  name="description"
                  label="Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  multiline
                  rows={3}
                  fullWidth
                />
              </Grid>


              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <FormControlLabel
                    sx={{justifyContent: 'start', marginLeft: '0px'}}
                    control={
                      <Switch
                        checked={formik.values.injured}
                        onChange={formik.handleChange}
                        name="injured"
                        color="primary"
                      />
                    }
                    label="Pet is injured?"
                    labelPlacement="start"
                    fullWidth
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <FormControlLabel
                    sx={{justifyContent: 'start', marginLeft: '0px'}}
                    control={
                      <Switch
                        checked={formik.values.inControl}
                        onChange={formik.handleChange}
                        name="inControl"
                        color="primary"
                      />
                    }
                    label="Pet is in control?"
                    labelPlacement="start" 
                    fullWidth
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <FormControlLabel
                    sx={{justifyContent: 'start', marginLeft: '0px'}}
                    control={
                      <Switch
                        checked={formik.values.hasCollarOrTag}
                        onChange={formik.handleChange}
                        name="hasCollarOrTag"
                        color="primary"
                      />
                    }
                    label="Has Collar or Tags ?"
                    labelPlacement="start"
                    fullWidth
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <Divider orientation="vertical" flexItem />
          <div className="w-full md:w-1/2 flex items-center flex-col">
            <Typography variant="h6" fontWeight="bold">Upload Pet Photos</Typography>
            {!!!selectedMedia.length && !!!uploadedMedia.length && (<ImageInput 
              onFileSelect={(files) => setSelectedMedia((prev) => ([...prev, ...files]))}
              multiple={true}
            />)}

            <Box sx={{display: 'flex', flexWrap: 'wrap', my: '16px', alignItems: 'center'}} fullWidth>
              {selectedMedia.map((item, index) => (
                <div style={{position: 'relative', padding: '8px'}}>
                  <IconButton
                    style={{position: 'absolute', top: 2, right: 1, zIndex: 1, color: '#5A5A5A'}}
                    onClick={() => {
                      setSelectedMedia((prev) => {
                        prev.splice(index, 1); 
                        return [...prev]
                      })
                    }}
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                  <img src={item?.base64} alt={`Uploaded Preview`} className="icon-preview" style={{height: '200px'}}/>
                </div>
              ))}
              {uploadedMedia.map((item, index) => (
                <div style={{position: 'relative', padding: '8px'}}>
                  <IconButton
                    style={{position: 'absolute', top: 2, right: 1, zIndex: 1, color: '#5A5A5A'}}
                    onClick={() => {
                      setUploadedMedia((prev) => {
                        prev.splice(index, 1); 
                        return [...prev]
                      })
                      setDeletedMedia((prev) => ([...prev, item?._id]))
                    }}
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                  <ImagePreview icon={item?.path} className="icon-preview" style={{height: '200px'}}/>
                </div>
              ))}
              {(!!selectedMedia.length || !!uploadedMedia.length) && (
                <ImageInput 
                  onFileSelect={(files) => setSelectedMedia((prev) => ([...prev, ...files]))}
                  multiple={true}
                />)
              }
            </Box>

            <Divider my={2} className="w-full" />
            <Typography  variant="h6" my='16px' fontWeight="bold">Upload Poster</Typography>
            <Box sx={{display: 'flex', flexWrap: 'wrap',  alignItems: 'center'}}>
              {poster && (
                  poster?.base64 
                    ? <img src={poster?.base64} alt="Uploaded Preview" className="icon-preview" style={{height: '200px'}} />
                    : poster.path && <ImagePreview icon={poster?.path} className="icon-preview" style={{height: '200px'}} />
                )
              }
              <ImageInput 
                onFileSelect={(files) => setPoster(files[0])}
                multiple={false}
              />
            </Box>
          </div>
        </div>
        <Box my={2}>
          <Button type="submit" variant="contained" color="primary" disabled={loading} >
            Submit
          </Button>
        </Box>
      </div>
    </form>
  );
}, 'pet-found-report', {query: {$populate: ['media', 'poster', 'user']}});

const initialValues = {
  type: '',
  rescueType: [],
  address: '',
  description: '',
  hasCollarOrTag: false,
  injured: false,
  inControl: false,
};

export default CreateUpdateForm;
