import {CircularProgress} from '@mui/material'
import {socketClient} from 'client/socket.client'
import {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import { PreviewImageDialog } from 'modules/pet-found/public-report/PreviewImageDialog'

/**
 * A component to fetch pre-signed image url and show preview
 *
 * @param {object} media - This is full media object.
 * @param {string} icon - This property contain path. like 45184354145456/avata.jpg
 * @param {string} className - Property which contain css classes name. like tailwind css class
 * @param {object} style - The object which contain css property
 *
 * Note: media or icon must be required
 */
const fallbackurl =
  'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930'
export const ImagePreview = props => {
  const {
    media,
    client = socketClient,
    service = 'content',
    icon,
    className = '',
    style = {},
    fallbackSrc = fallbackurl
  } = props
  const [loading, setLoading] = useState(true)
  const [url, setUrl] = useState(null)

  const dialogRef = useRef(null)

  const showPreview = () => {
    dialogRef.current.openDialog()
  }

  useEffect(() => {
    // console.log('media', media)
    if (icon || media) {
      setLoading(true)
      client
        .service(service)
        .find({
          query: {
            key: icon ? icon : media.path,
            noRedirect: true
          }
        })
        .then(result => {
          if (result?.url) {
            setUrl(result?.url)
          }
          setLoading(false)
        })
        .catch(error => {
          setLoading(false)
          console.error(error)
        })
    }
  }, [icon, media])

  return loading ? (
    <div className={`flex justify-center items-center ${className}`} style={style}>
      <CircularProgress />
    </div>
  ) : url ? (
    <>
      <img
        alt='preview'
        src={url}
        className={`${className} cursor-pointer`}
        style={style}
        onError={e => {
          e.target.onerror = null // Prevent infinite loop
          e.target.src = fallbackSrc

          console.log('image-preview-error', e)
        }}
        onClick={() => showPreview()}
      />
      {url && <PreviewImageDialog src={url} ref={dialogRef} />}
    </>
  ) : (
    <img
      alt='preview'
      src={fallbackSrc}
      className={className}
      style={style}
    />
  )
}

ImagePreview.prototype = {
  media: PropTypes.object,
  icon: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string
}
