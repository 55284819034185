import { Box, Button, Modal, TextField } from '@mui/material';
import { ModalLayout } from 'components/layouts/ModalLayout';
import { useState, forwardRef, useImperativeHandle } from 'react';

const AcceptRejectClaimModal = forwardRef((props, ref) => {
  const { onConfirm } = props;
  const [open, setOpen] = useState(false);  
  const [action, setAction] = useState(null); 
  const [note, setNote] = useState(null); 
  const [error, setError] = useState(false); 
  const [message, setMessage] = useState(null); 
  const [id, setId] = useState(null)

  useImperativeHandle(ref, () => ({
    openModal: (_id, action) => {
      console.log(_id, action)
      setOpen(true);
      setId(_id)
      setAction(action);
      if (action === 'accept') {
        setMessage('Are you sure you want to confirm claim?');
      } else if (action === 'reject') {
        setMessage('Are you sure you want to reject claim?');
      }
    },
  }));

  const handleClose = () => {
    setNote(null);
    setError(false);
    setOpen(false); 
  };

  const handleConfirm = () => {
    if (action === 'reject' && !note) {
      setError(true)
      return
    } 
    onConfirm(id, action, note)
    handleClose()
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalLayout title="Claim" onClose={handleClose}>
        <Box>
          <div>
            <p>{message}</p>
            {action === 'reject' && (
              <TextField
                sx={{my: 1}}
                label="Reason"
                placeholder="Please write reason to reject claim"
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                  setError(!!!e.target.value);
                }}
                error={error}
                helperText={error && 'Reason is required'}
                fullWidth
              />
            )}
            <div className="flex justify-end mt-4">
              <Button
                className="!mt-4"
                variant="contained"
                color="primary"
                onClick={handleConfirm}
                disabled={action === 'reject' && error}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Box>
      </ModalLayout>
    </Modal>
  );
});

export default AcceptRejectClaimModal;
