import Timeline from '@mui/lab/Timeline'
import TimelineItem, {timelineItemClasses} from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import moment from 'moment'
import {Card, IconButton, Tooltip, Typography, Collapse} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded'
import PetsRoundedIcon from '@mui/icons-material/PetsRounded'
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded'
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { ImagePreview } from 'components/ImagePreview'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { map, orderBy } from 'lodash'
/**
 * PetLostTimeLine
 *
 * @param {object} data - It is object of pet-finder detail. Which contain detail of scans array to show time line
 * @param {func} setPetLocationData - This is setter function to set location value of Pet (lat, lng) value
 */

const PetReportDetailCard = (props) => {
  const {item, setPetLocationData} = props
  const [expanded, setExpanded] = useState(false)

  return item?.activity ? (
    <Card variant="outlined" className="!p-3 flex flex-col">
      {item?.activity && (
        <p className="text-xs mt-1">{item?.activity}</p>
      )}
      <p className="text-xs mt-1">{moment.utc(item?.createdAt).format('MMMM Do YYYY, LT')}</p>
    </Card>
  ) : (
    <Card variant="outlined" className="!p-3 flex flex-col">
      <div className="flex justify-between">
        <div>
          {(item?.by?.firstName || item?.by?.lastName) && (
            <p className="text-xs">{`${item?.by?.firstName} ${item?.by?.lastName}` || ''}</p>
          )}

          {item?.time && (
            <p className="text-xs mt-1">{moment.utc(item?.time).format('MMMM Do YYYY, LT')}</p>
          )}
          {item?.note && <p className="text-xs md:max-w-[28rem] mt-2">{item?.note}</p>}
        </div>
        <IconButton
          style={{width: '24px', height: '24px'}}
          onClick={() => setPetLocationData(item.location)}
        >
          <PlaceRoundedIcon fontSize="18px" />
        </IconButton>
      </div>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {item?.media && <ImagePreview media={item?.media} className="w-40 mt-2" /> }
      </Collapse>
      <div className='w-full flex justify-end'>
        <div className='border rounded-full border-gray-400 cursor-pointer' onClick={() => setExpanded(!expanded)}>
          {expanded  ?  (<KeyboardArrowUpIcon />) : (<KeyboardArrowDownIcon />)}
        </div>
      </div>
    </Card>
  )
}

export const PetLostTimeLine = props => {
  const {data, setPetLocationData, lostPetActivity = []} = props
  const [timeline, setTimeline] = useState([])

  useEffect(() => {
    if (data?.scans?.length || lostPetActivity?.length) {
      const unSorted = map([...data?.scans, ...lostPetActivity], (item) => {
        if (item?.time) {
          item['createdAt'] = item?.time
        }
        return item
      })
      const sortedData = orderBy(unSorted, ['createdAt'], ['asc'])
      setTimeline(sortedData)
    }
  }, [data, lostPetActivity])
  return (
    <div className="flex flex-col my-8 md:my-0">
      <Typography className="!mb-2 !lg:ml-2 !font-bold">Tracking Updates: Sightings & Scans</Typography>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0
          }
        }}
      >
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot
              color="primary"
              sx={{width: 24, height: 24, padding: 0, alignItems: 'center', justifyContent: 'center'}}
            >
              <AssignmentRoundedIcon fontSize="24px" style={{color: 'white'}} />
            </TimelineDot>

            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Card variant="outlined" className="!p-3">
              <p className="text-xs font-bold	">Reported at</p>
              <p className="text-xs">
                {data?.createdAt ? moment.utc(data.createdAt).format('MMMM Do YYYY, LT') : ''}
              </p>
            </Card>
          </TimelineContent>
        </TimelineItem>
        {!!timeline?.length  &&
          timeline?.map((item, index) => (
            <TimelineItem key={index} className="!my-2">
              <TimelineSeparator>
                <TimelineDot
                  color="primary"
                  sx={{width: 24, height: 24, padding: 0, alignItems: 'center', justifyContent: 'center'}}
                >
                  {item?.helpful
                    ? <ThumbUpIcon fontSize="24px" style={{color: 'white'}} />
                    : <PetsRoundedIcon fontSize="24px" style={{color: 'white'}} />
                  }
                </TimelineDot>
                {(index !== timeline?.length - 1 || data?.claimed) && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent className="">
                <PetReportDetailCard item={item} setPetLocationData={setPetLocationData} />
              </TimelineContent>
            </TimelineItem>
          ))}

        {data?.claimed && data?.claimedBy && (
          <TimelineItem>
            <TimelineSeparator className="!mt-2">
              <CheckCircleIcon color="success" />
            </TimelineSeparator>
            <TimelineContent>
              <Tooltip>
                <Card variant="outlined" className="!p-3">
                  <p className="text-xs font-bold	">Claimed By</p>
                  <p className="text-xs mt-1 ">
                    {`${data?.claimedBy?.firstName} ${data?.claimedBy?.lastName}` || ''}
                  </p>
                </Card>
              </Tooltip>
            </TimelineContent>
          </TimelineItem>
        )}
      </Timeline>
    </div>
  )
}

PetLostTimeLine.prototype = {
  data: PropTypes.object.isRequired,
  setPetLocationData: PropTypes.func.isRequired
}
