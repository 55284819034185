import {Routes, Route} from 'react-router-dom'
import CreateUpdateReport from './CreateUpdateReport'
import PetFoundReports from './PetFoundReports'
import NotFound from 'modules/NotFound'
import ReportDetailView from './ReportDetailView'


export const PetFoundReport = props => {
  return (
    <Routes>
      <Route
        path="/"
        element={<PetFoundReports />}
      />
      <Route path="/create-update/:id?" element={<CreateUpdateReport />} />
      <Route path="/:id" element={<ReportDetailView />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
