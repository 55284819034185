import {Box, Button, Modal} from '@mui/material'
import {ModalLayout} from 'components/layouts/ModalLayout'
import { socketClient } from 'client/socket.client'
import { useState } from 'react'

export const DeleteConfirmationModal = props => {
  const {
    message = "This will delete the item permanently. You can not undo this action.",
    open, 
    handleModal, 
    id, 
    remove,
    hasMedia = false,
    mediaToDelete = [],
    loading
  } = props
  const [deleteingMedia, setDeletingMedia] = useState(false)

  const handleConfirmation = async () => {
    setDeletingMedia(true)
    if (hasMedia) {
      try {
        for (let i = 0; i < mediaToDelete.length; i++) {
          const id = mediaToDelete[i]
          await socketClient.service('media').remove(id)
        }
        setDeletingMedia(false)
      } catch (error) {
        setDeletingMedia(false)
        console.log('failed to delete media', error)
      }
    }
    remove(id)
    handleModal()
  }
  return (
    <Modal
      open={open}
      onClose={handleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalLayout title="Are you sure ?" onClose={handleModal}>
        <Box>
          <div>
            <p>{message}</p>
            <div className="flex justify-end mt-4">
              <Button
                className="!mt-4"
                variant="contained"
                color="primary"
                onClick={handleConfirmation}
                disabled={loading || deleteingMedia}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Box>
      </ModalLayout>
    </Modal>
  )
}
