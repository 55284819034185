import { AppLayout } from "modules/layout/AppLayout"
import { useParams } from "react-router-dom"
import { connectService } from "components/service/connectService"
import { Box, Divider, Grid} from "@mui/material"
import { ClaimsTable, ReadOnlyProperty } from "./components"
import { useEffect, useState } from "react"
import { getFullFormatedAddress } from "utils/utilities"
import { join, map, startCase } from "lodash"
import React from "react"
import { ImagePreview } from "components/ImagePreview"
const Details = connectService((props) => {
  const {data} = props
  const [location, setLocation] = useState(null)
  const [reportedLocation, setReportedLocation] = useState(null)

  useEffect(() => {
    if (data?.location?.length === 2) {
      getFullFormatedAddress(data?.location[1], data?.location[0])
        .then((address) => setLocation(address))
        .catch(console.log)
    }

    if (data?.reportedLocation?.length === 2) {
      getFullFormatedAddress(data?.reportedLocation[1], data?.reportedLocation[0])
        .then((address) => setReportedLocation(address))
        .catch(console.log)
    }
  }, [data]) 

  return (
    <div className="md:p-8 w-full flex flex-col items-start">
      <div className="w-full flex flex-col lg:flex-row gap-4">
        <div className="w-full md:w-1/2">
          <Grid container spacing={3} sx={{textAlign: 'left'}}>
             {data?.user?.firstName && data?.user?.lastName && (<Grid item xs={12}>
                <ReadOnlyProperty label={'Reported By'} value={`${data?.user?.firstName} ${data?.user?.lastName}`}/>
              </Grid>)}
              
              {data?.address && <Grid item xs={12}>
                <ReadOnlyProperty label='Address' value={data?.address}/>
              </Grid>}

              {data?.type && <Grid item xs={12}>
                <ReadOnlyProperty label='Report Type' value={startCase(data?.type)}/>
              </Grid>}

              {!!data?.rescueType?.length && <Grid item xs={12}>
                <ReadOnlyProperty label='Rescue Type' value={join(map(data?.rescueType, i => startCase(i)), ',')}/>
              </Grid>}

              {location && <Grid item xs={12}>
                <ReadOnlyProperty label='Location' value={location}/>
              </Grid>}
              
              {reportedLocation && <Grid item xs={12}>
                <ReadOnlyProperty label='Reported Location' value={reportedLocation}/>
              </Grid>}

              {data?.questionnaire?.map((item) => item?.answer && (
                <Grid item xs={6} key={item?._id}>
                  <ReadOnlyProperty label={`${startCase(item?.question)} ?`} value={startCase(item?.answer)}/>
                </Grid>
              ))}
            </Grid>
        </div>
        {(data?.media?.length || data?.poster) && (<Divider orientation="vertical" flexItem />)}
        {(data?.media?.length || data?.poster) &&
          <div className="w-full md:w-1/2">
            <Box sx={{display: 'flex', flexWrap: 'wrap', my: '16px', gap: 2}} fullWidth>
              {data?.media?.map((item, index) => (
                <ImagePreview icon={item?.path} className="icon-preview" style={{height: '200px'}} key={index}/>
              ))}
              {data?.poster && <ImagePreview icon={data?.poster?.path} className="icon-preview" style={{height: '200px'}} />}
            </Box>
          </div>
        }
      </div>
      <ClaimsTable claims={data?.claims} reportId={data?._id}/>
    </div>
  )
}, 'pet-found-report', {query: {$populate: ['media', 'poster', 'user', 'claims.by', 'claims.acceptedBy', 'claims.rejectedBy']}})


const ReportDetailView = (props) => {
  const {id} = useParams()
  return (
    <AppLayout heading="Pet Found Report">
      <Details id={id}/> 
    </AppLayout>
  )
}

export default ReportDetailView