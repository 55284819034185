import {useState} from 'react'
import {Analytics} from './Analytics'
import UserTable from './UserTable'
import {AppLayout} from 'modules/layout/AppLayout'
import {requireAuth} from 'modules/auth/components/requireAuth'
import {ConnectedComponent} from 'components/service/ConnectedComponent'
import {Chat} from '../messaging/Chat'

export const Dashboard = requireAuth(() => {
  const [showChat, setShowChat] = useState(false)

  window.enableBetaChat = (withUser) => {
    setShowChat(withUser)
  }

  return (
    <AppLayout heading="User Dashboard">
      <Analytics />
      <ConnectedComponent Component={UserTable} service="users" />
      {showChat ? <Chat participant={showChat}/> : null}
    </AppLayout>
  )
})
