import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { find } from 'lodash';
import { useEffect } from 'react';
import { useState } from 'react';
import { Typography } from '@mui/material';

export default function LostPetReportRanking({name, list, onChange}) {
  const [value, setValue] = useState('BY_REWARD');
  const [data, setData] = useState(null)
  
  useEffect(() => {
    if (list?.length) {
      const _data = find(list, (item) => item.key === name)
      setData(_data)
    }
  }, [list, name])

  useEffect(() => {
    if (data) {
      setValue(data?.value)
    }
  }, [data])

  const handleChange = (e) => {
    setValue(e.target?.value)
    onChange(data ? {_id: data?._id, value: e.target?.value, key: name} : {value: e.target?.value, key: name})
  }

  return (
    <FormControl>
      {/* <FormLabel id="lostPetReportRanking-group" sx={{fontWeight: 'bold', color: 'black'}}></FormLabel> */}
      <Typography textAlign="left">Lost Pet Report Ranking</Typography>
      <RadioGroup
        aria-labelledby="lostPetReportRanking"
        name="lostPetReportRanking"
        value={value}
        onChange={handleChange}
        sx={{flexDirection: 'row'}}

      >
        <FormControlLabel value="BY_REWARD" control={<Radio />} label="By Reward" />
        <FormControlLabel value="BY_DATE" control={<Radio />} label="By Date" />
      </RadioGroup>
    </FormControl>
  );
}

