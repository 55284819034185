import {useContext, useEffect, useState} from 'react'
import {
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Typography,
  Avatar,
  Chip,
  IconButton,
  Tooltip,
  Box,
  CircularProgress
} from '@mui/material'
import {StyledTable, StyledTableRow, StyledTableHeadCell, StyledTableCell} from 'components/StyledComponent'
import moment from 'moment'
import placeholder from 'images/avatar_placeholder.svg'
import { ImagePreview } from 'components/ImagePreview'
import { startCase } from 'lodash'
import DirectionsIcon from '@mui/icons-material/Directions';
import { DetailView } from '../modal/DetailView'
import DownloadIcon from '@mui/icons-material/CloudDownload';
import { jsPDF } from 'jspdf';                // Import jsPDF
import autoTable from 'jspdf-autotable';      // Import autoTable plugin
import { getFormattedDateTime, getFullFormatedAddress } from 'utils/utilities'
import axios from 'axios'
// import PrinterIcon from '@mui/icons-material/LocalPrintshop';
import { socketClient } from 'client/socket.client'
import { SnackbarContext } from 'components/snackbar/SnackbarContext';

const status = {
  pending: 'primary',
  transit: 'secondary',
  scheduled: 'info',
  complete: 'success'
}

export const DataTable = props => {
  const {
    list,
    loading,
    Loader,
    Paginator,
    isRefreshTable,
    setRefreshTable,
    refreshTable
  } = props

  const {setSeverity, showToast} = useContext(SnackbarContext)
  
  const [openDetailView, setOpenDetailView] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [showActionLoading, setShowActionLoading] = useState(false)

  useEffect(() => {
    if (isRefreshTable) {
      refreshTable()
      setRefreshTable(false)
    }
  }, [isRefreshTable])

  const getPetProfile = (report) => {
    if (report?.media?.length) {
      return report.media[0]
    }

    if (report?.pet?.profile) {
      return report?.pet?.profile
    }

    return null
  }

  function openGoogleMaps(source, destination) {
    const { lat: srcLat, lng: srcLng } = source;
    const { lat: destLat, lng: destLng } = destination;
  
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${srcLat},${srcLng}&destination=${destLat},${destLng}`;
    window.open(googleMapsUrl, '_blank');
  }

  const handlePdfOperation = async (action_type) => {
    try {
      const doc = new jsPDF(); 
      setShowActionLoading(true)
      const _data = []
      const _list  = await socketClient.service('transport-order').find({query: {
        $populate: [
          'vehicle',
          'toUser',
          {
            path: 'pet',
            populate: ['profile', 'type', 'user']
          }
        ],
        $sort: {
          updatedAt: -1
        },
        $limit: -1
      }})

      if (!_list?.length) {
        setSeverity('error')
        showToast(`No transport order found`)
        setShowActionLoading(false)
      }
      console.log(_list)
      for (let i = 0; i < _list.length; i++) {
        const { fromLocation, toLocation, ...others } = _list[i]
    
        const originAddress = await getFullFormatedAddress(fromLocation[1], fromLocation[0]);
        const destinationAddress = await getFullFormatedAddress(toLocation[1], toLocation[0]);
        
        const vehicle = () => {
          return `${others?.vehicleName || ''}${others?.vehicleNumber ? `-${others?.vehicleNumber}`: ''} ${others?.type ? `(${others?.type})` : ''}`
        }
        const item =  {
          pet: others?.pet?.name,
          shelter: others?.shelterId,
          transportTo: `${others?.toUser?.firstName} ${others?.toUser?.lastName}`,
          vehicle: vehicle(),
          origin: originAddress,
          destination: destinationAddress,
          status: startCase(others?.status),
          createdAt: moment(others.createdAt).format('DD MMM YYYY'),
          updatedAt: moment(others.updatedAt).format('DD MMM YYYY')
        }
        _data.push(Object.values(item))
      } 
      const columns = ['Pet', 'Shelter', 'Transport To', 'Vehicle', 'Origin', 'Destination', 'Status', 'Created At', 'Updated At']
      autoTable(doc, {
        head: [columns],   
        body: _data,       
        theme: 'grid',     
        headStyles: { fillColor: 'black' }, 
        styles: { fontSize: 10, cellPadding: 2 },  
        margin: { top: 20 }
      });
      
      // if (action_type === 'print') {
      //   console.log('print')
      //   const pdfBlob = doc.output('blob');
      //   const pdfUrl = URL.createObjectURL(pdfBlob);
      //   window.open(pdfUrl, '_blank');
      // } else if (action_type === 'download') {
        doc.save(`transport_order_${getFormattedDateTime()}.pdf` )
      // }
      setShowActionLoading(false)
    } catch (error) {
      setSeverity('error')
      showToast(`Failed to ${action_type} transport order`)
      setShowActionLoading(false)
      console.log(error) 
    }
  }

  return (
    <div>
      <Box sx={{display: 'flex', justifyContent: 'end', alignItems:'center', padding: '8px'}}>
        {showActionLoading ? <CircularProgress size="24px" /> : <Box>
          {/* <Tooltip title="Print Transport order" >
            <IconButton size='small' onClick={() => handlePdfOperation('print')} sx={1}>
              <PrinterIcon color='primary'/>
            </IconButton>
          </Tooltip> */}
          <Tooltip title="Download PDF">
            <IconButton size='small' onClick={() => handlePdfOperation('download')}>
              <DownloadIcon color='primary'/>
            </IconButton>
          </Tooltip>
        </Box>}
      </Box>
      <Paper sx={{width: '100%', maxHeight: '800px', overflow: 'auto'}}>
        <TableContainer>
          <StyledTable stickyHeader aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                <StyledTableHeadCell>
                </StyledTableHeadCell>
                <StyledTableHeadCell>Pet Name</StyledTableHeadCell>
                <StyledTableHeadCell>Shelter</StyledTableHeadCell>
                <StyledTableHeadCell>Transport To</StyledTableHeadCell>
                <StyledTableHeadCell>Vehicle</StyledTableHeadCell>
                <StyledTableHeadCell>Vehicle Type</StyledTableHeadCell>
                <StyledTableHeadCell>Status</StyledTableHeadCell>
                <StyledTableHeadCell>Updated at</StyledTableHeadCell>
                <StyledTableHeadCell></StyledTableHeadCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {list.length > 0 ? (
                list.map((row, index) => (
                  <StyledTableRow
                    key={index}
                    odd={index % 2 !== 0 ? 1 : 0}
                    onClick={() => {
                      setOpenDetailView(true)
                      setSelectedItem(row?._id)
                    }}
                  >
                    <StyledTableCell>
                      {getPetProfile(row) ? (
                        <ImagePreview
                          media={getPetProfile(row)}
                          className="w-10 h-10  rounded-full"
                          fallbackSrc={placeholder}
                        />
                      ) : (
                        <Avatar src={placeholder}></Avatar>
                      )}
                    </StyledTableCell>
                    <StyledTableCell data-label="Pet Name">
                      {row?.pet?.name ? row.pet?.name : '-'}
                    </StyledTableCell>
                    <StyledTableCell data-label="Shelter">
                      {row?.shelterId?.toString()}
                    </StyledTableCell>
                    
                    <StyledTableCell data-label="Transport To">
                      {row?.toUser?.firstName || row?.toUser?.lastName ? `${row?.toUser?.firstName} ${row?.toUser?.lastName}` : '-'}
                    </StyledTableCell>
                    <StyledTableCell data-label="Vehicle">
                      {row?.vehicleName}-{row?.vehicleNumber}
                    </StyledTableCell>
                    <StyledTableCell data-label="Vehicle Type">
                      {row?.type}
                    </StyledTableCell>
                    <StyledTableCell data-label="Status">
                      {row?.status && 
                        <Chip label={startCase(row?.status)} size="small" color={status[row?.status]} variant="outlined" />
                      }
                    </StyledTableCell>
                    <StyledTableCell data-label="Updated At">
                      {row?.updatedAt ? moment(row.updatedAt).format('DD MMM YYYY') : ''}
                    </StyledTableCell>
                    <StyledTableCell size="small">
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault()
                          openGoogleMaps(
                            {lng: row.fromLocation[0], lat: row.fromLocation[1]},
                            {lng: row.toLocation[0], lat: row.toLocation[1]}
                          )
                        }}
                      >
                        <DirectionsIcon fontSize="small" />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : loading ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={8} align="center">
                    <Loader />
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={8} align="center">
                    <Typography>No Data</Typography>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
        <Paginator />
      </Paper>
      {openDetailView && <DetailView 
        id={selectedItem}
        open={openDetailView} 
        onClose={() => {
          setOpenDetailView(false)
          setSelectedItem(null)
        }} 
      />}
    </div>
  )
}
