import { AppLayout } from "modules/layout/AppLayout"
import { CreateUpdateForm } from "./components"
import { useParams } from "react-router-dom"

const CreateUpdateReport = (props) => {
  const {id} = useParams()
  return (
    <AppLayout heading="Create Pet Found Report">
      <CreateUpdateForm id={id}/> 
    </AppLayout>
  )
}

export default CreateUpdateReport