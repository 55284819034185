import { useEffect, useState } from 'react'
import { MapContainer, TileLayer, Marker, Popup, Polyline, useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import FmdGoodRoundedIcon from '@mui/icons-material/FmdGoodRounded'
import { renderToString } from 'react-dom/server'
import PropTypes from 'prop-types'
import L from 'leaflet'

/**
 * SourceDestinationMap
 *
 * @param {Array} fromLocation - From location as [lng, lat]
 * @param {Array} toLocation - To location as [lng, lat]
 * @param {string} width - Width of the map container (default '100%')
 * @param {string} height - Height of the map container (default '100%')
 */
const FitBounds = ({ bounds }) => {
  const map = useMap()  // Access the map instance

  useEffect(() => {
    if (bounds) {
      map.fitBounds(bounds, { padding: [50, 50] })  // Fit bounds with padding
    }
  }, [bounds, map])

  return null
}

export const SourceDestinationMap = ({ fromLocation, toLocation, width = '100%', height = '100%' }) => {
  // Define the bounds for fitting the map
  const [bounds, setBounds] = useState(null)

  useEffect(() => {
    if (fromLocation?.length && toLocation?.length) {
      const sourceLocation = [fromLocation[1], fromLocation[0]]
      const targetLocation = [toLocation[1], toLocation[0]]

      // Update the bounds for fitBounds
      setBounds([sourceLocation, targetLocation])
    }
  }, [fromLocation, toLocation])

  // Define custom icon for markers
  const pinkIcon = new L.DivIcon({
    className: 'custom-icon',
    html: renderToString(<FmdGoodRoundedIcon style={{ color: '#ED1690' }} />),
    iconSize: [32, 32],
  })

  return (
    <MapContainer
      style={{ width: width, height: height, position: 'relative' }}
      center={fromLocation ? [fromLocation[1], fromLocation[0]] : [51.505, -0.09]} // Default center
      zoom={13}
      scrollWheelZoom={false}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      
      {fromLocation && (
        <Marker position={[fromLocation[1], fromLocation[0]]} icon={pinkIcon}>
          <Popup>Origin</Popup>
        </Marker>
      )}

      {toLocation && (
        <Marker position={[toLocation[1], toLocation[0]]} icon={pinkIcon}>
          <Popup>Destination</Popup>
        </Marker>
      )}

      <Polyline
        positions={[
          fromLocation ? [fromLocation[1], fromLocation[0]] : [0, 0],
          toLocation ? [toLocation[1], toLocation[0]] : [0, 0],
        ]}
        color="red"
        weight={5}
      />

      {/* Fit bounds to the markers */}
      {bounds && <FitBounds bounds={bounds} />}
    </MapContainer>
  )
}

SourceDestinationMap.propTypes = {
  fromLocation: PropTypes.arrayOf(PropTypes.number).isRequired, // [lng, lat]
  toLocation: PropTypes.arrayOf(PropTypes.number).isRequired, // [lng, lat]
  width: PropTypes.string,
  height: PropTypes.string
}
