import {AppLayout} from 'modules/layout/AppLayout'
import {useEffect, useState} from 'react'
import {AddEditModal} from './modals/AddEditModal'
import {DataTable} from './components/DataTable'
import {DeleteModal} from './modals/DeleteModal'
import {BrowseLayout} from 'components/layouts/BrowseLayout'
import {connectService} from 'components/service/connectService'
import {isEmpty} from 'lodash'
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
} from '@mui/material'
import { ConnectedComponent } from 'components/service/ConnectedComponent'
import { AutoCompleteSearch } from './components/AutoCompleteSearch'

export const LostPet = connectService(
  props => {
    const [open, setOpen] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [refreshTable, setRefreshTable] = useState(false)
    const {additionalFilters, setAdditionalFilters} = props

    const handleModal = (data = null) => {
      if (data) setRefreshTable(true)
      setSelectedItem(null)
      setOpen(!open)
    }

    const handleOnEdit = item => {
      setSelectedItem(item)
      setOpen(!open)
    }

    const handleDeleteModal = (data = null) => {
      if (data) setRefreshTable(true)
      setSelectedItem(null)
      setOpenDeleteModal(!openDeleteModal)
    }

    const handleOnDelete = item => {
      setSelectedItem(item)
      setOpenDeleteModal(!openDeleteModal)
    }

    useEffect(() => {
      if (!open) {
        setSelectedItem(null)
      }
    }, [open])

    const onStatusChange = (e) => {
      if (isEmpty(e.target.value)) {
        handleFilterChange('claimed', e.target.value)
        return
      }
      handleFilterChange('claimed', e.target.value === 'true' ? true : false)
    }

    const handleFilterChange = (field, value) => {
      const filters = {...additionalFilters}
      if (typeof value === 'string' && isEmpty(value)) {
        delete filters[field]
        setAdditionalFilters({...filters})
        return
      }
      setAdditionalFilters({...filters, [field] : value})
    }


    return (
      <AppLayout heading="Lost Pets Reports">
        <BrowseLayout
          title="Lost Pets"
          onAdd={() => setOpen(!open)}
          showSearchField={false}
          allowToCreate={false}
          {...props}
        >
          <Grid className="!p-4" container alignItems="center" spacing="10px">
            <Grid item xs={12} lg={2} md={3}>
              <FormControl sx={{width: '100%'}} size="small">
                <InputLabel id="demo-select-small-label">Status</InputLabel>
                <Select
                  label="Status"
                  size='small'
                  onChange={onStatusChange}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="true">Claimed</MenuItem>
                  <MenuItem value="false">Not-Claimed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={2} md={3}>
              <TextField
                className="!w-full"
                label="Reward Amount"
                type="text"
                size="small"
                placeholder="0"
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  endAdornment: <span>USD</span>
                }}
                onChange={(e) =>
                  handleFilterChange('rewardAmount', e.target.value ? Number(e.target.value) : '')
                }
              />
            </Grid>
            <Grid item xs={12} lg={2} md={3}>
              <ConnectedComponent
                Component={AutoCompleteSearch}
                service="pets"
                label="Search By Pet"
                className="!w-full"
                onChange={value => handleFilterChange('pet', value || '') }
              />
            </Grid>

            {/* <Grid item xs={12} lg={2} md={3}>
              <TextField
                className="!w-full"
                label="Owner"
                type="text"
                size="small"
                placeholder="Owner name"
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) =>
                  handleFilterChange('user.firstName', e.target.value)
                }
              />
            </Grid>

            <Grid item xs={12} lg={2} md={3}>
              <ConnectedComponent
                Component={AutoCompleteSearch}
                service="pet-types"
                className="w-full"
                onChange={value => handleFilterChange('pet.type', value)}
              />
            </Grid> */}
          </Grid>
          <DataTable
            onEdit={handleOnEdit}
            onDelete={handleOnDelete}
            isRefreshTable={refreshTable}
            setRefreshTable={setRefreshTable}
            {...props}
          />
        </BrowseLayout>
        {open && <AddEditModal open={open} setOpen={setOpen} handleModal={handleModal} id={selectedItem} />}
        {openDeleteModal && (
          <DeleteModal
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            handleModal={handleDeleteModal}
            id={selectedItem}
          />
        )}
      </AppLayout>
    )
  },
  'pet-finder',
  {query: {
    $populate: [
      'user',
      'media',
      'claimedBy',
      {
        path: 'pet',
        populate: ['profile', 'type']
      }
    ]
  }}
)
