import {Table,  TableCell, TableRow} from '@mui/material'
import {styled} from '@mui/material/styles'

const StyledTable = styled(Table)({
  width: '100%',
  borderCollapse: 'collapse'
})

const StyledTableRow = styled(TableRow)(({theme, odd}) => ({
  background: odd ? 'rgba(0, 0, 0, 0.04)' : 'inherit',
  borderBottom: '1px solid #ccc'
}))

const StyledTableHeadCell = styled(TableCell)({
  // background: '#333',
  // color: 'white',
  fontWeight: 'bold',
  [`@media (max-width: 850px)`]: {
    display: 'none'
  }
})

const StyledTableCell = styled(TableCell)(({theme}) => ({
  display: 'block',
  border: 'none',
  position: 'relative',
  paddingLeft: '50%',
  [theme.breakpoints.up('md')]: {
    display: 'table-cell',
    // border: '1px solid #ccc',
    borderBottom: 'none',
    position: 'static',
    paddingLeft: '1rem'
  },
  '&:before': {
    content: 'attr(data-label)',
    position: 'absolute',
    top: '50%', // Vertically center the content
    transform: 'translateY(-50%)', // Adjust for vertical centering
    left: '6px',
    width: '45%',
    paddingRight: '10px',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      content: 'none'
    }
  }
}))

export {StyledTable, StyledTableRow, StyledTableHeadCell, StyledTableCell}
