import React from "react"
import { BrowseLayout } from "components/layouts/BrowseLayout"
import { AppLayout } from "modules/layout/AppLayout"
import { useNavigate } from "react-router-dom"
import { connectService } from "components/service/connectService"
import { DataTable } from "./components/DataTable"

const PetFoundReports = connectService((props) => {
  const navigate = useNavigate()

  return (
    <AppLayout heading="Pet Found Reports">
      <BrowseLayout  
        showSearchField={false}
        onAdd={() => navigate('/pet-found-reports/create-update')} 
        {...props}
      >
        <DataTable {...props}/>
      </BrowseLayout>
    </AppLayout>
  )
}, 'pet-found-report', {query: {$populate: ['user']}})

export default PetFoundReports