import axios from 'axios'
import {v4 as uuidv4} from 'uuid'

export const getLocationAddress = (latitude, longitude) => {
  const REVRSE_GEO_CODE_URL = 'https://maps.googleapis.com/maps/api/geocode/json'
  const GOOGLE_MAP_API_KEY = 'AIzaSyCKUD0ZyB5keBxxDPzvuwD_ssMcZg6hWws'

  return axios.get(`${REVRSE_GEO_CODE_URL}?key=${GOOGLE_MAP_API_KEY}&latlng=${latitude},${longitude}`)
}

export function getBrowserId() {
  let key = localStorage.getItem('browserId')
  if (!key) {
    key = uuidv4()
    localStorage.setItem('browserId', key)
    return key
  }
  return key
}

export function getFormattedDateTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
}

export const getFullFormatedAddress = async (lat, lng) => {
  return new Promise((resolve, reject) => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
    axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`)
      .then((response) => {
        if (response?.data?.results.length && response?.data?.results[0]?.formatted_address) {
          resolve(response?.data?.results[0]?.formatted_address)
        } else {
          reject('Address not found')
        }
      }).catch(() => reject('Address not found'))
  })
  
}
