import { Box, Chip, Divider, Typography } from "@mui/material"
import CustomDrawer from "components/CustomDrawer"
import { ImagePreview } from "components/ImagePreview";
import { connectService } from "components/service/connectService"
import PetsIcon from '@mui/icons-material/Pets';
import PersonIcon from '@mui/icons-material/Person';
import TruckIcon from '@mui/icons-material/LocalShipping';
import ShelterIcon from '@mui/icons-material/NightShelter';
import { startCase } from "lodash";
import { SourceDestinationMap } from "components/map/SourceDestinationMap";
import QRCode from "react-qr-code";
import { Fragment, useEffect, useMemo, useState } from "react";
import { getFullFormatedAddress } from "utils/utilities";
import LocationIcon from '@mui/icons-material/LocationOn';

const OrderItem = ({value, label, icon}) => {
  return (
    <Fragment>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        {icon}
        <Box mx={1}>
          <Typography fontSize="11px" color="GrayText">{label}</Typography>
          <Typography>{value}</Typography>
        </Box>
      </Box>
      <Divider style={{ margin: '16px 0' }} />
    </Fragment>
  )
}

const status = {
  pending: 'primary',
  transit: 'secondary',
  scheduled: 'info',
  complete: 'success'
}

export const DetailView = connectService(props => {
  const {open, onClose, data} = props
  const [originAddress, setOriginAddress] = useState(null)
  const [destinationAddress, setDestinationAddress] = useState(null)

  const qrString = useMemo(() => {
    if (!data?.handoff?.handoffs?.length) return null

    const signature = data?.handoff?.handoffs?.find((item) => item?.active)
    
    return signature ?? null
  }, [data])

  const getPetProfile = (report) => {
    if (report?.media?.length) {
      return report.media[0]
    }

    if (report?.pet?.profile) {
      return report?.pet?.profile
    }

    return null
  }

  function openGoogleMaps(source, destination) {
    
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${source[1]},${source[0]}&destination=${destination[1]},${destination[0]}`;
    window.open(googleMapsUrl, '_blank');
  }

  useEffect(() => {
    if (data?.fromLocation?.length === 2 && data?.toLocation?.length === 2) {
      getFullFormatedAddress(data?.fromLocation[1], data?.fromLocation[0])
        .then((address) => setOriginAddress(address))
        .catch(console.log)

      getFullFormatedAddress(data?.toLocation[1], data?.toLocation[0])
        .then((address) => setDestinationAddress(address))
        .catch(console.log)
    }
  }, [data])

  return (
    <CustomDrawer open={open} onClose={onClose} width={450}>     
      <ImagePreview media={getPetProfile(data)} className="w-[20ch] h-[20ch] object-cover rounded-xl mb-4 mx-2" />
      {data?.status && 
        <Chip label={startCase(data?.status)} sx={{marginBottom: '16px', marginLeft: '8px'}} size="small" color={status[data.status]} variant="outlined" />
      }
      
      {data?.pet?.name && <OrderItem 
        label="Pet Name"
        value={data?.pet?.name}
        icon={<PetsIcon color="primary"/>}
      />}

      {data?.toUser?.firstName && <OrderItem 
        label="Transport To"
        value={data?.toUser?.firstName || data?.toUser?.lastName ? `${data?.toUser?.firstName} ${data?.toUser?.lastName}` : '-'}
        icon={<PersonIcon color="primary"/>}
      />}
           

      {(data?.vehicleName || data?.vehicleNumber ||  data?.type) && (<OrderItem 
        label="Transport Vehicale"
        value={`${data?.vehicleName} ${data?.vehicleNumber} (${data?.type})`}
        icon={<TruckIcon color="primary"/>}
      />)}
           

      {data?.shelterId && <OrderItem 
        label="Shelter Number"
        value={`${data?.shelterId}`}
        icon={<ShelterIcon color="primary"/>}
      />}

      {originAddress && <OrderItem 
        label="Origin"
        value={`${originAddress}`}
        icon={<LocationIcon color="primary"/>}
      />}

      {destinationAddress && <OrderItem 
        label="Destination"
        value={`${destinationAddress}`}
        icon={<LocationIcon color="primary"/>}
      />}
      
      {qrString && 
        <Box 
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography mb={2} variant="body2" color="GrayText">Handoff/Reunite QR Code</Typography>
          <QRCode value={qrString} size={124} />
          <Divider style={{ margin: '16px 0' }} />
        </Box>
      }

      

      {data?.fromLocation?.length && data?.toLocation.length && 
        (<Box height="200px">
          <Typography 
            sx={{
              color: 'blue',
              textAlign: 'right',
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
            mb={2}
            onClick={() => openGoogleMaps(data?.fromLocation, data?.toLocation)}
          >
            Open Location in Google Map
          </Typography>
          <SourceDestinationMap fromLocation={data?.fromLocation} toLocation={data?.toLocation}/>
        </Box>)
      }

      <Divider style={{ margin: '24px 0' }} />

      {data?.updatedAt && <Typography variant="body2" color="textSecondary">
        Last Updated: {new Date(data?.updatedAt).toLocaleString()}
      </Typography>}
          
    </CustomDrawer>
  )
}, 
'transport-order', 
{query: {
  $populate: [
    'toUser',
    'handoff',
    {
      path: 'pet',
      populate: ['profile', 'type', 'user']
    }
  ]
}}
)