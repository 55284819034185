import React from 'react';
import { Drawer, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CustomDrawer = ({ open, onClose, width = 350, children, anchor = "right" }) => {
  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onClose}
    >
      <div style={{ width, padding: '16px' }}>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          style={{ position: 'absolute', top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
        {children}
      </div>
    </Drawer>
  );
};

export default CustomDrawer;
