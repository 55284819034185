const { Box, Typography } = require("@mui/material")
const { Fragment } = require("react")

const ReadOnlyProperty = ({value, label}) => {
  return (
    <Fragment>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Box mx={1}>
          <Typography fontSize="11px" color="GrayText">{label}</Typography>
          <Typography>{value}</Typography>
        </Box>
      </Box>
    </Fragment>
  )
}
export default  ReadOnlyProperty