import { Box, Button, Chip, Divider, Paper, TableBody, TableContainer, TableHead, Typography } from "@mui/material"
import { StyledTable, StyledTableCell, StyledTableHeadCell, StyledTableRow } from "components/StyledComponent"
import moment from "moment"
import { useEffect, useState, useRef, useContext } from "react"
import { orderBy, startCase } from "lodash"
import AcceptRejectClaimModal from "../modals/AcceptRejectClaimModal"
import { socketClient } from "client/socket.client"
import { SnackbarContext } from "components/snackbar/SnackbarContext"

const ClaimsTable = (props) => {
  const {claims, reportId} = props
  const [data, setData] = useState([])
  const modalRef = useRef()
  const {setSeverity, showToast} = useContext(SnackbarContext)

  useEffect(() => {
    if (!claims?.length) return
    const sorted = orderBy(claims, ['time'], ['desc'])
    setData(sorted)

  }, [claims])

  const onAccept = (id) => {
    modalRef.current.openModal(id, 'accept')
  }

  const onReject = (id) => {
    modalRef.current.openModal(id, 'reject')
  }

  const handleResult = (result) => {
    if (result) {
      const _claims = result?.claims?.length ? result?.claims : []
      const sorted = orderBy(_claims, ['time'], ['desc'])
      setData(sorted)
      setSeverity('success')
      showToast('Report updated Successfuly')
    }
  }

  const handleError = (error) => {
    console.log(error)
    setSeverity('success')
    showToast('Failed to update report!')
  }

  const handleConfirm = (id, action, note) => {
    const payload = {
      claimId: id,
      reportId
    }

    if (action === 'accept') {
      socketClient.service('pet-found-report').approveClaim(payload)
        .then(handleResult)
        .catch(handleError)
    }

    if (action === 'reject') {
      socketClient.service('pet-found-report').rejectClaim({...payload, note})
        .then(handleResult)
        .catch(handleError)
    }
  }

  const renderAction = (action) => {
    if (action?.accepted) {
      return <Chip label='Accepted' size="small" color={'success'} variant="outlined" />
    }

    if (action?.rejected) {
      return <Chip label='Rejected' size="small" color={'error'} variant="outlined" />
    }

    return (<Box sx={{display: 'flex', flexDirection: 'column'}}>
      <Button color="success" variant="outlined" size="small" sx={{width: '100px'}} onClick={() => onAccept(action?._id)}>
        Accept
      </Button>
      <Button sx={{mt: 1, width: '100px'}} color="error" variant="outlined" size="small" onClick={() => onReject(action?._id)}>
        Reject
      </Button>
    </Box>)
  }

  return (
    <Box className="w-full" textAlign="left">
      <Divider className="w-full" sx={{my: 2}}/>
      <Typography variant="h5" fontWeight="bold" mb={2}>Claims</Typography>
      <Paper sx={{maxHeight: '800px', overflow: 'auto'}}>
        <TableContainer sx={{ overflowX: 'auto', display: 'block'}}>
          <StyledTable stickyHeader aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                <StyledTableHeadCell>Type</StyledTableHeadCell>
                <StyledTableHeadCell>ShelterId</StyledTableHeadCell>
                <StyledTableHeadCell>Created By</StyledTableHeadCell>
                <StyledTableHeadCell>Note</StyledTableHeadCell>
                <StyledTableHeadCell>Time</StyledTableHeadCell>
                <StyledTableHeadCell>Action</StyledTableHeadCell>
                <StyledTableHeadCell>Accepted By</StyledTableHeadCell>
                <StyledTableHeadCell>Accepted At</StyledTableHeadCell>
                <StyledTableHeadCell>Rejected By</StyledTableHeadCell>
                <StyledTableHeadCell>Rejected At</StyledTableHeadCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {data?.length > 0 ? (
                  data.map((row, index) => (
                    <StyledTableRow
                      key={index}
                      odd={index % 2 !== 0 ? 1 : 0}
                    >
                      <StyledTableCell data-label="Type">
                        {startCase(row?.type)}
                      </StyledTableCell>
                      <StyledTableCell data-label="Shelter">
                        {row?.claimByShelterId ? row?.claimByShelterId : '-'}
                      </StyledTableCell>
                      
                      <StyledTableCell data-label="Created By">
                        {row?.by?.firstName} {row?.by?.lastName}
                      </StyledTableCell>

                      <StyledTableCell data-label="Note">
                        {row?.note}
                      </StyledTableCell>
                      <StyledTableCell data-label="Time">
                        {row?.time ? moment(row.time).local().format('MMM DD, YYYY, LT') : ''}
                      </StyledTableCell>
                      <StyledTableCell data-label="Action">
                        {renderAction(row)}
                      </StyledTableCell>
                      <StyledTableCell data-label="Accepted By">
                        {row?.acceptedBy?.firstName} {row?.acceptedBy?.lastName}
                      </StyledTableCell>
                      <StyledTableCell data-label="Accepted At">
                        {row?.acceptedAt ? moment(row.acceptedAt).local().format('MMM DD, YYYY, LT') : ''}
                      </StyledTableCell>
                      <StyledTableCell data-label="Rejected By">
                        {row?.rejectedBy?.firstName} {row?.rejectedBy?.lastName}
                      </StyledTableCell>
                      <StyledTableCell data-label="Rejected At">
                        {row?.rejectedAt ? moment(row.rejectedAt).local().format('MMM DD, YYYY, LT') : ''}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                )  : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={10} align="center">
                      <Typography>No Claim Submitted yet</Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </Paper>
      <AcceptRejectClaimModal
        ref={modalRef}
        onConfirm={handleConfirm}
      />
    </Box>
  )
}

export default ClaimsTable
