import {
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Typography,
  Chip,
  Tooltip,
  IconButton,
} from '@mui/material'
import {StyledTable, StyledTableRow, StyledTableHeadCell, StyledTableCell} from 'components/StyledComponent'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import {Delete, Edit} from '@mui/icons-material'
import { connectService } from 'components/service/connectService'
import { DeleteConfirmationModal } from 'components/DeleteConfirmationModal'
import { useState } from 'react'

export const DataTable = props => {
  
  const {
    list,
    loading,
    Loader,
    Paginator,
    refreshTable
  } = props
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [mediaToDelete, setMediaToDelet] = useState([])

  const navigate = useNavigate()
  const DeleteConfirmationDialog = connectService(DeleteConfirmationModal, 'pet-found-report')

  const handleOnDelete = (item) => {
    setSelectedItem(item._id)
    const _media = item?.media.length ? item?.media : []
    
    if (item?.poster) _media.push(item?.poster)
    
    if (_media?.length) setMediaToDelet(_media)
    
    setOpenDeleteModal(true)
  }

  const handleDeleteResponse = () => {
    setMediaToDelet([])
    setSelectedItem(null)
    setOpenDeleteModal(false)
    refreshTable()
  }
  return (
    <div>
      <Paper sx={{width: '100%', maxHeight: '800px', overflow: 'auto'}}>
        <TableContainer>
          <StyledTable stickyHeader aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                <StyledTableHeadCell>Finder Name</StyledTableHeadCell>
                <StyledTableHeadCell>Address</StyledTableHeadCell>
                <StyledTableHeadCell>Description</StyledTableHeadCell>
                <StyledTableHeadCell>Claimed</StyledTableHeadCell>
                <StyledTableHeadCell>Closed</StyledTableHeadCell>
                <StyledTableHeadCell>Updated at</StyledTableHeadCell>
                <StyledTableHeadCell></StyledTableHeadCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {list.length > 0 ? (
                list.map((row, index) => (
                  <StyledTableRow
                    key={index}
                    odd={index % 2 !== 0 ? 1 : 0}
                    onClick={() => {
                      navigate(`/pet-found-reports/${row?._id}`)
                    }}
                  >
                    <StyledTableCell data-label="Finder Name">
                      {row?.user?.firstName || ''} {row?.user?.lastName || ''}
                    </StyledTableCell>
                    <StyledTableCell data-label="Address">
                      <Tooltip title={row?.address}>
                        {row?.address ? `${row?.address?.slice(0, 35)}...` : ''}
                      </Tooltip>
                    </StyledTableCell>
                    
                    <StyledTableCell data-label="Description">
                      <Tooltip title={row?.description}>
                        {row?.description ? `${row?.description?.slice(0, 35)}...` : ''}
                      </Tooltip>
                    </StyledTableCell>

                    <StyledTableCell data-label="Vehicle">
                      <Chip label={row?.claimed ? 'Yes' : 'No'} size="small" color={row?.claimed ? 'success' : 'error'} variant="outlined" />
                    </StyledTableCell>
                    <StyledTableCell data-label="Vehicle Type">
                      <Chip label={row?.closed ? 'Yes' : 'No'} size="small" color={row?.closed ? 'success' : 'error'} variant="outlined" />
                    </StyledTableCell>
                    <StyledTableCell data-label="Updated At">
                      {row?.updatedAt ? moment(row.updatedAt).format('DD MMM YYYY') : ''}
                    </StyledTableCell>
                    <StyledTableCell size="small">
                      <IconButton id='edit-found-report' onClick={(e) => {
                        e.stopPropagation()
                        navigate(`/pet-found-reports/create-update/${row?._id}`)
                      }}>
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton onClick={(e) => {e.stopPropagation(); handleOnDelete(row)}}>
                        <Delete fontSize="small" />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : loading ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={8} align="center">
                    <Loader />
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={8} align="center">
                    <Typography>No Data</Typography>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
        <DeleteConfirmationDialog 
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          handleModal={handleDeleteResponse}
          mediaToDelete={mediaToDelete} 
          id={selectedItem}
          hasMedia={true}
        />
        <Paginator />
      </Paper>
    </div>
  )
}
