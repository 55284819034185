import {AppLayout} from 'modules/layout/AppLayout'
import {useState} from 'react'
import {DataTable} from './components/DataTable'

import {BrowseLayout} from 'components/layouts/BrowseLayout'
import {connectService} from 'components/service/connectService'

export const TransportOrders = connectService(
  props => {
    const [refreshTable, setRefreshTable] = useState(false)

    return (
      <AppLayout heading="Transport Orders">
        <BrowseLayout
          title=""
          showSearchField={false}
          allowToCreate={false}
          {...props}
        >
          <DataTable
            isRefreshTable={refreshTable}
            setRefreshTable={setRefreshTable}
            {...props}
          />
        </BrowseLayout>
      </AppLayout>
    )
  },
  'transport-order',
  {query: {
    $populate: [
      'vehicle',
      'toUser',
      {
        path: 'pet',
        populate: ['profile', 'type', 'user']
      }
    ],
    $sort: {
      updatedAt: -1
    }
  }}
)
