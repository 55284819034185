// src/pages/NotFound.js
import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>404</h1>
      <p style={styles.message}>Oops! The page you're looking for doesn't exist.</p>
      <Link to="/" style={styles.link}>Go Back to Home</Link>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f7f7f7",
    textAlign: "center",
  },
  header: {
    fontSize: "72px",
    fontWeight: "bold",
    color: "#ff6347",
  },
  message: {
    fontSize: "24px",
    margin: "20px 0",
  },
  link: {
    fontSize: "18px",
    color: "#007bff",
    textDecoration: "none",
    border: "1px solid #007bff",
    padding: "10px 20px",
    borderRadius: "5px",
    transition: "all 0.3s ease",
  },
};

export default NotFound;
