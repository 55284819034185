import React from 'react'
import './ImageInput.css'
import CameraAltIcon from '@mui/icons-material/CameraAlt'

export const ImageInput = props => {
  const {onFileSelect, multiple = false} = props

  const handleImageChange = event => {
    const files = event.target.files
    const fileArray = []

    // Loop through all selected files and process each one
    Array.from(files).forEach(file => {
      const reader = new FileReader()
      reader.onloadend = () => {
        fileArray.push({ file, base64: reader.result })
        // Call onFileSelect once all files have been processed
        if (fileArray.length === files.length) {
          onFileSelect(fileArray)
        }
      }
      reader.readAsDataURL(file)
    })
  }


  return (
    <div className="lost-pet-upload-dialog-container mt-4">
      <div id="upload-area" className="upload-area">
        <div className="drag-drop-label">
          <CameraAltIcon />
        </div>
        <input
          id="imageInput"
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{width: '100%', height: '100%', opacity: 0, cursor: 'pointer'}}
          multiple={multiple}
        />
      </div>
    </div>
  )
}
